<template>
  <div class="register-container">
    <div>
      <div class="header" style="align-self: start;">
        <h2 class="primary-title" style="margin-bottom: 0">Welcome Agent,</h2>
        <h4 class="primary-title" style="margin-top: 0;color: #fde383">ยินดีต้อนรับ</h4>
      </div>
      <div class="input-content" style="align-self: center;margin: 5rem 0rem;">
        <div class="input-group">
          <label class="disabled">Phone Number - เบอร์โทรศัพท์</label>
          <input class="background-panel" type="text" v-model="phone_number" />
        </div>

        <div class="input-group">
          <label class="disabled">Password - รหัสผ่าน</label>
          <input class="background-panel" type="password" v-model="password" />
        </div>

        <button :disabled="loading" class="highlight-primary th" @click="login_agent()" style="color: #fde383;width: 85%;margin: 1rem">เข้าสู่ระบบ Agent</button>
      </div>
    </div>
  </div>
</template>
<script>
import AgentService from '../../services/AgentService'

export default {
  name: 'register',
  data () {
    return {
      loading: false,
      phone_number: '',
      password: '',
    }
  },
  mounted() {
    if (localStorage.getItem('agent')) {
      this.$router.push('/agent/dashboard')
    }
  },
  methods: {
    async login_agent() {
      this.loading = true
      try {
        const res = await AgentService.login(this.phone_number, this.password)
        if (res) {
          location.reload()
        }
      } catch (err) {
        this.loading = false
        alert('รหัสใช้งานไม่ถูกต้อง!')
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .input-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .input-group {
    padding: 1rem;
    width: 100%;

    select {
      color: #fde383;
      border: none;
      border-radius: 5px;
    }
    input {
      color: #fde383;
      padding: 0.75rem;
      width: 92%;
      border: none;
      border-radius: 5px;
      font-size: 20px;
    }
  }
  label {
    font-size: 14px;
  }
  a {
    cursor: pointer;
  }
  footer {
    display: flex;
    justify-content: space-evenly;
  }
  .active {
    border-bottom: 1px solid #fde383;
  }
  .register-container {
    border-radius: 1rem;
    padding: 1.5rem;
    display: flex;
    margin: 1.5rem;
    margin-bottom: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #fde383;
    background-color: #1c2530;
  }
  .register-container::-webkit-scrollbar {
    display: none
  }
</style>
